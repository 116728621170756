<template>
  <div
    class="page page-index -theme-dark"
  >
    <component
      :is="headers[c.type]"
      v-for="(c) in headerModules"
      :key="`c-${c}`"
      :data="c"
      :experience="experience"
    />
    <component
      :is="modules[c.type]"
      v-for="(c) in contentModules"
      :key="`c-${c}`"
      :data="c"
    />
    <GlobalFooter />
  </div>
</template>

<script setup lang="ts">
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { type Experience } from '~/webgl/webglExperience';
import { type ExperienceProjects } from '~/webgl/webglExperienceProjects';
import { injectStrict } from '~/common/inject';
import { StatamicLinksInjectionKey } from '~/content/statamicLinks';
import useStatamicEntryApi from '~/content/useStatamicEntryApi';
import {
  type StatamicPage
} from '~/content/StatamicTypes';
import handleSeo from '~/common/handleSeo';
import { AppTheme, ThemeInjectionKey } from '~/common/useTheme';
import { headers } from '~/cms-headers/headers';
import { modules } from '~/cms-modules/modules';
import GlobalFooter from '~/cms-modules/GlobalFooter.vue';
import { usePlausibleTracking } from '~/common/usePlausibleTracking';

const router = useRouter();
const route = useRoute();

const props = defineProps<{
  experience: Experience;
  experienceProjects: ExperienceProjects;
}>();

definePageMeta({
  middleware: 'adjust-scroll',
  scrollPosition: {
    y: 0,
    x: 0
  }
});

const { locale } = useI18n();

const { setTheme } = injectStrict(ThemeInjectionKey);

const { findPageIDBySlug, links } = injectStrict(StatamicLinksInjectionKey);
const dataCMS = await useStatamicEntryApi<StatamicPage>({
  collection: 'pages',
  id: findPageIDBySlug('home')
}, locale.value);

handleSeo(dataCMS?.seo ?? null);

const headerModules = dataCMS?.header_module ?? [];
const contentModules = dataCMS?.content_modules ?? [];

onMounted(async () => {
  setTheme(AppTheme.dark);

  await nextTick();
  // @ts-ignore
  const scrollPosition = route.meta.scrollPosition?.y || 0;
  const scrollToY = scrollPosition <= 0 ? 0 : scrollPosition;
  const setScroll = ScrollTrigger.getScrollFunc(window);

  setScroll(scrollToY);
  usePlausibleTracking('home');
});

</script>

<style scoped lang="scss">

.page-index {
}

.gridonic-logo-section {
  height: 0;
}

</style>
